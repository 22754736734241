import React from "react"
import styled from "styled-components"

import ErrorIMG from "../images/undraw_warning_cyit.svg"
import SEO from "../components/seo"

const Root = styled.div`
  width: 100%;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
`

const IMG = styled.img`
  width: 30%;
  margin-bottom: 2rem;
`

const ErrorPage = () => (
  <>
    <SEO title="Error" />
    <Root>
      <IMG src={ErrorIMG} />
      <h1>WE'RE UNDER MAINTENANCE!</h1>
      <p>Sorry about that! Please order by calling us at 905-762-1500 for now!</p>
    </Root>
  </>
)

export default ErrorPage
